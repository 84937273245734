<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/5">
        {{ item.reference }}
      </div>

      <div class="w-1/5 flex items-center ml-4">
        <div class="text-c16">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </div>
      </div>

      <div class="w-1/5 ml-4">
        {{ new Date(item.estimate.expireDate).toLocaleDateString() }}
      </div>

      <div class="w-1/5 ml-4">
        <bouton
            label="CNSNL"
            height="32px"
            color="#F82626"
            background="#FEE9E9"
            radius="10px"
            size="16px"
            v-if="item.status === 'CNSNL'"
            class="w-1/2"
        />
        <bouton
            label="CSNL"
            height="32px"
            color="#FBA705"
            background="#FFF6E6"
            radius="10px"
            size="16px"
            v-if="item.status === 'CSNL'"
            class="w-1/2"
        />
        <bouton
            label="CSL"
            height="32px"
            color="#00C24E"
            background="#E5F9ED"
            radius="10px"
            size="16px"
            v-if="item.status === 'CSL'"
            class="w-1/2"
        />
      </div>

      <div class="w-1/5 ml-4">
        {{ item.estimate.financialData.totalAmount.toLocaleString() }} F
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../../assets/icons/entreprise.svg'
import email from '../../../../assets/icons/email.svg'
import down from '../../../../assets/icons/down.svg'
import bouton from '../../../helper/add/button'

export default {
  name: "Index",
  components: {
    bouton
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
    amount: function () {
      let amount = (this.item.financialData.totalAmount + this.item.financialData.deliveryAmount)-(this.item.financialData.discountAmount + this.item.financialData.supportAmount)
      return amount.toLocaleString()
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
