<template>
  <div class="w-full flex">
    <div
      v-for="(item, index) in option"
      :key="index"
      class="text-c18 text-90 font-c5 pl-5 pr-5 cursor-pointer etat w-full flex items-center justify-center"
      :class="{'item': position === item}"
      @click="selected(item)"
    >
      <div> {{ item }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",
  props: {
      option: {
        type: Array,
        default: null
      },
    value: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      position: null
    }
  },

  created () {
    this.position = this.value
  },

  methods: {
    selected(item){
      this.position = item
      this.$emit('info', item)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

  .etat {
    border-right: 1px solid #DCDCE4;
    border-radius: 4px 4px 0px 0px;
    background-color: #F6F6F6;
    height: 49px;
  }
  .item {
    border-top: 4px solid $oho-blue;
    color: $oho-blue;
    background-color: white;
  }

</style>
