<template>
  <div>
    <popupCharge :activation="charge" v-if="charge"/>

    <newEvent
        v-if="activeEvent"
        :donne="donnes"
        :activation="activeEvent"
        @oga="retourEvent"
    />

    <div>
      <updateUser
        v-if="activeUpdate"
        :activation="activeUpdate"
        :donne="donnes"
        @oga="retourUpdate"
      />
      <div
        class="flex items-center"
      >
        <div
          class="w-2/3 flex text-left text-c48 items-center font-c6"
          @click="activeOption = false"
        >
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="goBack"
          />
          <div class="ml-4">
            {{ donnes.name }} {{ donnes.surname }}
          </div>
        </div>

        <div class="w-1/3">
          <div class="flex justify-end">
            <div class="w-2/5">
              <bouton
                label="Créer"
                :icon="icons.add"
                radius="5.4px"
                weight="600"
                size="17px"
                @oga="retourClick"
              />
              <selectFiltre
                v-if="activeOption"
                :option="options"
                @info="retourOption"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-6"
        @click="activeOption = false"
      >
        <div class="rounded-10 h-auto bg-white w-full p-8">
          <div>
            <div class="flex items-center">
              <div class="text-c24 font-c6">
                {{ donnes.name }} {{ donnes.surname }}
              </div>
              <icon
                :data="icons.mail"
                height="35"
                width="35"
                class="ml-6 cursor-pointer"
                original
              />
              <icon
                :data="icons.update"
                height="35"
                width="35"
                class="ml-4 cursor-pointer"
                original
                @click="activeUpdate = true"
              />
            </div>

            <div class="text-c16 text-60 text-left mt-3">
             {{ donnes.phone }}<span class="ml-4 mr-4">|</span>
              <span class="mr-4" v-if="donnes.whatsapp !== null && donnes.whatsapp !== ''">{{ donnes.whatsapp }} <span class="ml-4">|</span> </span>
              {{ donnes.email }}
            </div>

            <steep
              :option="etape"
              :value="selectedEtape"
              class="mt-10"
              @info="retourSteep"
            />
            <div class="diviser" />
          </div>

          <div class="mt-4" v-if="stats !== null && !this.charge">
            <apercu v-if="selectedEtape === 'Aperçu' && !reload" :donnes="donnes" :stats="stats" />
            <devis v-if="selectedEtape === 'Devis'" :donnes="stats.estimates" />
            <commande v-if="selectedEtape === 'Commandes'" :donnes="stats.orders" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import bouton from '../../helper/add/button'
import add from '../../../assets/icons/add.svg'
import mail from '../../../assets/icons/mail.svg'
import update from '../../../assets/icons/update.svg'
import steep from './steepSelect'
import apercu from './details/apercu'
import selectFiltre from '../../helper/form/selectFiltre'
import devis from './details/devis'
import commande from './details/commande'
import updateUser from '../../popup/updateClient'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import popupCharge from '../../popup/popupCharge'
import newEvent from '../../popup/newEvent'

export default {
  name: "Index",

  components: {
    bouton,
    steep,
    apercu,
    selectFiltre,
    devis,
    commande,
    updateUser,
    popupCharge,
    newEvent
  },
  props: {
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        add,
        mail,
        update
      },
      donnes: null,
      etape: ['Aperçu', 'Devis', 'Commandes'],
      selectedEtape: 'Aperçu',
      options: ['Devis', 'Evénement'],
      valueOption: '',
      activeOption: false,
      activeUpdate: false,
      reload: false,
      stats: null,
      charge: true,
      activeEvent: false,
    }
  },

  created () {
    this.donnes = this.donne
    this.getStats()
  },

  methods: {
    goBack () {
      this.$emit('oga', true)
    },

    retourSteep (answer) {
      this.selectedEtape = answer
    },

    retourUpdate (answer) {
      if (answer !== false) {
        // this.donnes = answer
        console.log(answer)
        this.donnes.meta = answer.meta
      }
      this.activeUpdate = false
    },

    retourEvent (answer) {
      if (answer) {
       this.getStats()
      }
      this.activeEvent = false
    },

    retourOption (answer) {
      this.valueOption = answer
      if (this.valueOption === 'Devis') {
        this.$store.dispatch('saveDevisUser', this.donnes)
        this.$router.push('/nouveauDevis')
      }
      if (this.valueOption === 'Evénement') {
        this.activeEvent = true
      }
      this.activeOption = false
    },

    retourClick () {
      this.activeOption =!  this.activeOption
    },

    getStats () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.clientStat + '?id=' + this.donnes.id)
          .then(response => {
            console.log('statttttt')
            console.log(response)
            this.stats = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
</style>
