<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Paiement
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="mt-2 text-white p-1 text-c16 bg-red">
              TOUTE SOMME ENCAISSÉE N’EST PLUS REMBOURSABLE
            </div>

            <div class="text-left text-c12 mt-6">
              Moyen de paiement
            </div>
            <multiselect
                :option="allInfo"
                background="white"
                class="mt-2"
                width="36%"
                value-t="Sélectionnez le moyen utilisé"
                @info="retourMoyen"
            />

            <div class="mt-6">
              <div class="text-left text-c12">
                Montant
              </div>

              <div class="mt-2">
                <inputo
                    type="number"
                    @info="retourMontant"
                />
              </div>
            </div>

            <div class="mt-6" v-if="position !== 'CASH'">
              <div class="text-left text-c12">
                Référence du moyen de paiement
              </div>

              <div class="mt-2">
                <inputo @info="retourReference"/>
              </div>
            </div>

            <div v-if="position === 'BANK_CHECK'">
              <div class="mt-6">
                <div class="text-left text-c12">
                  Banque*
                </div>

                <div class="mt-2">
                  <inputo
                      @info="retourBanque"
                  />
                </div>
              </div>

              <div class="mt-6">
                <div class="text-left text-c12">
                  Tireur*
                </div>

                <div class="mt-2">
                  <inputo
                      @info="retourPorteur"
                  />
                </div>
              </div>
            </div>


            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import multiselect from '../helper/form/multiselect'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    multiselect,
    inputo
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    estimation: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      date: '',
      error: null,
      charge: false,
      allInfo: ['Espèce', 'Mobile Money', 'Chèque'],
      position: '',
      montant: 0,
      reference: '',
      banque: '',
      porteur: ''
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    retourBanque (answer) {
      this.banque = answer
    },

    retourPorteur (answer) {
      this.porteur = answer
    },

    continuer () {
      this.error = null
      if (this.position !== 'Sélectionnez le moyen utilisé' && this.reference !== null && this.montant !== null) {
        let body = {
          client: this.donne.client.id,
          amount: parseInt(this.montant),
          method: this.position,
          meta: {
            payementType: this.position,
            reference: this.reference,
            banque: this.banque,
            porteur: this.porteur
          }
        }
        if (this.position === 'BANK_CHECK'){
          if (this.banque.length > 0 && this.porteur.length > 0 && this.reference !== ''){
            this.save(body)
          }
          else {
            this.error = 'La banque et le porteur sont obligatoire'
          }
        } else {
          this.charge = true
          this.save(body)
        }

      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.recharge, body)
          .then(response => {
            console.log(response)
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    retourReference (answer) {
      this.reference = answer
    },

    retourMontant (answer) {
      if (answer === ''){
        this.montant = 0
      } else {
        this.montant = answer
      }

    },

    retourMoyen (answer) {
      this.position = answer
      if (answer === this.allInfo[0]) {
        this.position = 'CASH'
      }
      if (answer === this.allInfo[1]) {
        this.position = 'MOBILE_MONEY'
      }
      if (answer === this.allInfo[2]) {
        this.position = 'BANK_CHECK'
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .bloc{
      height: 36px;
      background: rgba(251, 167, 5, 0.2);
      border-radius: 5px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
