<template>
  <section class="success">
    <assurance :activation="activeAssureur" v-if="activeAssureur" @oga="retourAssurance"/>

    <popup-base
      v-show="wantToMakeOffer"
      class="popo w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div class="success-container h-full pt-6">
          <div class="text-left flex pl-6 pr-6">
            <div class="text-c24 font-c7 w-4/5">
              Modifier client
            </div>

            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-left text-c14 font-c5 mt-2">
              INFORMATION D'IDENTITÉ
            </div>

            <div class="flex mt-10">
              <inputo
                class="w-1/2"
                placeholder="Nom *"
                :value-t="donnes.name"
                @info="retourNom"
              />
              <inputo
                class="w-1/2 ml-6"
                placeholder="Prénom *"
                :value-t="donnes.surname"
                @info="retourPrenom"
              />
            </div>

            <div class="flex mt-6">
              <div class="w-1/2">
                <datepicker
                  placeholder="Date de naissance"
                  :max-date="new Date()"
                  :choseTime="false"
                  format="dd-MM-yyyy"
                  :value-t="donnes.birthday"
                  @info="retourDate"
                />
              </div>
              <div class="w-1/2 ml-6">
                <multiselect
                  :value-t="donnes.sex"
                  background="white"
                  :option="optionSexe"
                  @info="retourSexe"
                />
              </div>
            </div>

            <div class="flex mt-6">
              <inputo
                class="w-1/2"
                placeholder="Profession"
                :value-t="donnes.profession"
                @info="retourProfession"
              />
              <inputo
                class="w-1/2 ml-6"
                placeholder="Entreprise"
                :value-t="donnes.enterprise"
                @info="retourEntreprise"
              />
            </div>

            <div class="flex mt-6">
              <inputo
                class="w-1/2"
                placeholder="Ville"
                :value-t="donnes.city"
                @info="retourVille"
              />
              <inputo
                class="w-1/2 ml-6"
                placeholder="Adresse"
                :value-t="donnes.address"
                @info="retourAdresse"
              />
            </div>

            <div class="flex mt-6 items-center">
              <div class="w-1/2">
                <multiselect
                    :value-t="clientType"
                    background="white"
                    :option="optionType"
                    @info="retourType"
                />
              </div>
              <div class="w-1/2 ml-6 text-left flex items-center text-90">
                <icon
                    :data="icons.ic_agence"
                    v-if="valueAssurance !== null && clientType === 'Client partenaire'"
                    height="40"
                    width="40"
                    class="cursor-pointer"
                    original
                />

                <div v-if="valueAssurance !== null && clientType === 'Client partenaire'" class="ml-2 text-c14">
                  {{valueAssurance.name}}
                </div>
              </div>
            </div>

            <div class="text-left text-c14 font-c5 mt-10">
              CONTACT
            </div>

            <div class="flex mt-6">
              <div class="w-1/2">
                <inputo
                  placeholder="Email"
                  type="email"
                  :value-t="donnes.email"
                  @info="retourEmail"
                />

                <phoneNumber
                  class="mt-6"
                  placeholder="Whatsapp"
                  :value-t="donnes.whatsapp"
                  @info="retourWhatsapp"
                />
              </div>

              <div class="w-1/2 ml-6">
                <div class="flex items-center">
                  <phoneNumber
                    class="w-4/5"
                    placeholder="Telephone *"
                    :value-t="donnes.phone"
                    @info="retourTelephone"
                  />
                  <div class="w-1/5 ml-6">
                    <div
                      class="w-10 h-10 rounded-50 add flex items-center justify-center cursor-pointer"
                      @click="plus"
                    >
                      <div class="text-c18">
                        +
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="donnes.meta.ortherPhone">
                  <div
                    v-for="(item, index) in donnes.meta.ortherPhone"
                    :key="index"
                    class="mt-4"
                  >
                    <add-phone
                        :valueT="item"
                      :index="index"
                      @info="retourPhone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="text-left text-43 text-c12 flex items-center mt-12">
              <div>Facebook</div>
              <div class="ml-6">
                <switcher @info="retourFacebook" />
              </div>
              <div class="ml-6 w-1/2" v-if="activeFacebook || donnes.facebook">
                <inputo placeholder="Lien Facebook" :value-t="donnes.facebook" @info="retourFac" />
              </div>
            </div>

            <div class="text-left text-43 text-c12 flex items-center mt-6">
              <div>Linkedin</div>
              <div class="ml-6">
                <switcher @info="retourLinkedin" />
              </div>
              <div class="ml-6 w-1/2" v-if="activeLinkedin || donnes.linkedin">
                <inputo placeholder="Lien Linkedin" :value-t="donnes.linkedin" @info="retourLin"/>
              </div>
            </div>

            <div class="text-left text-43 text-c12 flex items-center mt-6">
              <div>Instagram</div>
              <div class="ml-6">
                <switcher @info="retourTelegram" />
              </div>
              <div class="ml-6 w-1/2" v-if="activeTelegram || donnes.instagram">
                <inputo placeholder="Lien Instagram" :value-t="donnes.instagram" @info="retourTel"/>
              </div>
            </div>

            <div class="text-left text-43 text-c12 flex items-center mt-6">
              <div>Twitter</div>
              <div class="ml-6">
                <switcher @info="retourTwitter" />
              </div>
              <div class="ml-6 w-1/2" v-if="activeTwitter || donnes.twitter">
                <inputo placeholder="Lien Twitter" :value-t="donnes.twitter" @info="retourTwi"/>
              </div>
            </div>


            <div class="mt-12">
              <textare
                placeholder="Note"
                radius="5px"
                size="14px"
                border="1px solid #DDDDDD"
                :value-t="donnes.note"
                @info="retourNote"
              />
            </div>

            <div class="flex items-center text-left mt-6">
              <div class="w-1/5">
                <upload height="109px" @info="retourFile"/>
              </div>

              <div class="w-5/6 pl-6">
                <div class="text-c18 font-c5">
                  Photo de profil
                </div>
                <div class="text-90 text-c16 mt-2 w-4/5">
                  Non obligatoire. Merci de ne pas déranger le client pour cela
                </div>
              </div>
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex pl-6 pr-6 bg-white pb-10">
            <div class="w-1/2">
              <buton
                background="#C4C4C4"
                color="#000000"
                label="Annuler"
                height="55px"
                :charge="charge"
                @oga="fermer"
              />
            </div>

            <div class="w-1/2 ml-6">
              <buton
                label="Enregistrer"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupBase2'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import datepicker from '../helper/form/datePicker'
import phoneNumber from '../helper/form/phoneNumber'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import addPhone from "../cdevs/clients/addphone"
import multiselect from '../helper/form/multiselect'
import switcher from '../helper/form/switcher'
import textare from '../helper/form/textarea'
import upload from '../helper/form/upload2'
import assurance from './assurances'

export default {
  name: 'Success',
  components: { assurance, PopupBase, buton, inputo, phoneNumber, addPhone, datepicker, multiselect, switcher, textare, upload },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      error: null,
      charge: false,
      addSession: [],
      optionSexe: ['Masculin', 'Feminin'],
      activeFacebook: false,
      activeTelegram: false,
      activeLinkedin: false,
      activeTwitter: false,
      clientType: '',
      optionType: ['Client direct', 'Client partenaire', 'Client prospecté'],
      activeAssureur: false,
      valueAssurance: null,
      note: '',
      url: null,
      file: null
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.donnes = this.donne
    this.note = this.donnes.note
    console.log('donnes', this.donnes)

    if (this.donnes.type === 'PARTNER') {
      this.clientType = 'Client partenaire'
    }

    if (this.donnes.type === 'DIRECT') {
      this.clientType = 'Client direct'
    }

    if (this.donnes.type === 'PROSPECTED') {
      this.clientType = 'Client prospecté'
    }

    if (this.donnes.insurance !== undefined && this.donnes.insurance !== null) {
      if (this.donnes.insurance.length > 0) {
        this.valueAssurance = this.donnes.insurance[0]
      }
    }
  },

  methods: {
    retourFile (answer) {
      this.file = answer
    },

    retourType(answer) {
      if (this.clientType !== answer) {
        this.clientType = answer
        if (answer === 'Client partenaire') {
          this.activeAssureur = true
          this.donnes.type = 'PARTNER'
        }

        if (answer === 'Client prospecté') {
          this.donnes.type = 'PROSPECTED'
        }

        if (answer === 'Client direct') {
          this.donnes.type = 'DIRECT'
        }
      }
    },

    retourAssurance (answer) {
      if (answer !== false) {
        this.valueAssurance = answer
        this.donnes.meta.assurance = answer
        this.donnes.insurance = this.valueAssurance.id
      }
      this.activeAssureur = false
    },

    retourTel(answer){
      this.donnes.instagram = answer
    },

    retourFac(answer){
      this.donnes.facebook = answer
    },

    retourLin(answer){
      this.donnes.linkedin = answer
    },
    retourTwi(answer){
      this.donnes.twitter = answer
    },

    retourFacebook (answer) {
      this.activeFacebook = answer
    },

    retourTwitter (answer) {
      this.activeTwitter = answer
    },

    retourLinkedin (answer) {
      this.activeLinkedin = answer
    },

    retourWhatsapp (answer) {
      this.donnes.whatsapp = answer
    },

    retourTelegram (answer) {
      this.activeTelegram = answer
    },

    fermer () {
      this.$emit('oga', false)
    },

    plus () {
      if (this.donnes.meta.ortherPhone){
        this.donnes.meta.ortherPhone.push('')
      } else {
        this.donnes.meta.ortherPhone = ['']
      }
    },

    async continuer() {
      this.charge = true
      this.error = null
      if (this.file !== null) {
        await this.saveFile()
      }

      if (this.url !== null) {
        this.donnes.meta.urlProfile = this.url
      }

      this.save(this.donnes)
    },

    save (body) {
      http.put(apiroutes.baseURL + apiroutes.updateClient, body)
          .then(response => {
            console.log('update save')
            console.log(response)
            this.$emit('oga', response)
            this.charge = false
            if (this.donnes.note !== this.note) {
              this.saveEvent(response)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    saveEvent (client) {
      http.post(apiroutes.baseURL + apiroutes.createEvent, {
        client: client.id,
        date: new Date(),
        type: "NOTE",
        note: this.donnes.note
      })
          .then(response => {
            console.log('note')
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    //Ici j'enregistre les fichiers et je récupère des url
    async saveFile () {
      this.charge = true
      let data = new FormData()
      data.append('files', this.file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      await http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            console.log('file')
            console.log(response)
            this.url = response[0].url
          })
          .catch(error => {
            console.log(error)
          })
    },

    retourNote(answer){
      this.donnes.note = answer
    },

    retourNom (answer) {
      this.donnes.name = answer
    },
    retourPrenom (answer) {
      this.donnes.surname = answer
    },
    retourDate (answer) {
      this.donnes.birthday = answer
    },
    retourSexe (answer) {
      this.donnes.sex = answer[0].toUpperCase()
    },
    retourVille (answer) {
      this.donnes.city = answer
    },
    retourEntreprise (answer) {
      this.donnes.entreprise = answer
    },

    retourProfession (answer) {
      this.donnes.profession = answer
    },
    logState () {
      // this.wantToMakeOffer = answer
      // this.$emit('oga', false)
    },

    retourAdresse (answer) {
      this.donnes.address = answer
    },

    retourTelephone (answer) {
      this.donnes.phone = answer
    },

    retourPhone (answer) {
      this.addSession[answer.index] = answer.phoneNumber

        if (this.donnes.meta.ortherPhone){
          this.donnes.meta.ortherPhone[answer.index] = answer.phoneNumber
        } else {
          this.donnes.meta.ortherPhone = [answer.phoneNumber]
        }
    },

    retourEmail (answer) {
      this.donnes.email = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 100%;
    }

    .add{
      background-color: #ECECEC;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: 100vh;
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: auto;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
