<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center">
        <nameLabel
          :first="item.name"
          :second="item.surname"
          height="42px"
          width="42px"
        />
        <div class="ml-4">
          <div class="text-c16">
            {{ item.name }}  {{ item.surname }}
          </div>
          <div class="text-90 text-c12">
            {{item.enterprise}}
          </div>
        </div>
      </div>

      <div class="w-1/6 ml-4">
        {{ item.phone }}
      </div>

      <div class="w-1/5 ml-4">
        <span v-if="item.email !== null && item.email !== undefined">
          {{ item.email.substr(0, 22) }}
          <span v-if="item.email.length > 22">...</span>
        </span>
        <span v-if="item.email === null || item.email === undefined">-</span>
      </div>

      <div class="w-1/6 ml-4">
        {{item.agency.label}}
      </div>

      <div class="w-32 ml-4">
        <span v-if="item.stats">{{ item.stats.letToPayAmount.toLocaleString() }} f</span>
        <span v-if="!item.stats">...</span>
      </div>

      <div class="w-6 ml-4">
        <icon
          :data="icons.down"
          height="15"
          width="15"
          class="cursor-pointer"
          original
        />
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import nameLabel from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
    nameLabel
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
