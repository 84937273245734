<template>
  <div class="p-10 pr-16">
    <newClient
      v-if="activeClient"
      :activation="activeClient"
      @oga="retourClient"
    />
    <filtre
        v-if="activeFiltre"
        :activation="activeFiltre"
        @oga="retourFiltre"
    />
    <div v-if="!activeDetail">
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Clients <span v-if="user !== null">({{user}})</span>
          </div>
        </div>

        <div class="w-1/2 flex justify-end">
          <inputo :icon="icons.search" @info="retourSearch" height="50px" placeholder="Rechercher..." class="w-1/2" />

          <div class="w-1/2 pl-10">
            <div class="flex justify-end">
              <div class="w-full">
                <bouton
                    label=" Nouveau client"
                    :icon="icons.add"
                    radius="5.4px"
                    weight="600"
                    size="17px"
                    @oga="retourClick"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-3">
        <bouton
            class="w-1/5"
            label="Filtrage poussé"
            radius="5.4px"
            background="#fff"
            color="#909090"
            border="1px solid #909090"
            weight="600"
            size="17px"
            @oga="activeFiltre = true"
        />
      </div>

      <div class="mt-6">
        <global-view
          v-if="!reloadSession"
          :search="recherche"
          :filtrage="filtrage"
          @info="retourEtape"
          @user="retourUser"
          @filt="filtrage = null"
        />
      </div>
    </div>

    <div v-if="activeDetail && selectedItem !== null">
      <detail
        :donne="selectedItem"
        @oga="retourBack"
      />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import search from '../../assets/icons/search.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/clients/globalData'
import newClient from '../../component/popup/newClient'
import add from '../../assets/icons/add.svg'
import detail from '../../component/cdevs/clients/details'
import filtre from '../../component/popup/filtre'
import inputo from '../../component/helper/form/input'

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    newClient,
    detail,
    filtre,
    inputo
  },

  data () {
    return {
      icons: {
        back,
        add,
        search
      },
      activeClient: false,
      reloadSession: false,
      activeDetail: false,
      selectedItem: null,
      user: null,
      recherche: null,
      activeFiltre: false,
      filtrage: null
    }
  },

  created () {
    this.$store.dispatch('saveQuote', null)
    this.$store.dispatch('saveBasket', [])
    this.$store.dispatch('saveCoupon', null)
    this.$store.dispatch('saveUpdate', null)
    this.$store.dispatch('saveDevisUser', null)
  },

  methods: {
    retourFiltre (answer) {
      if (answer !== false) {
        this.filtrage = answer
      }
      this.activeFiltre = false
    },

    retourSearch (answer) {
      this.recherche = answer
    },

    retourClick () {
      this.activeClient = true
    },

    retourBack () {
      this.selectedItem = null
      this.activeDetail = false
    },

    retourUser (answer) {
      this.user = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },

    retourClient (answer) {
      if (answer){
        // this.reloadSession = answer
        // setTimeout(() => {
        //   this.reloadSession = false
        // }, 200)
        this.retourEtape(answer)

      }
      this.activeClient = false
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
