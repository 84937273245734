<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/4">
            Nom complet
          </div>
          <div class="w-1/6 ml-4">
            Contacts
          </div>
          <div class="w-1/5 ml-4">
            Email
          </div>
          <div class="w-1/6 ml-4">
            Agence
          </div>
          <div class="w-32 ml-4">
            Montant dû
          </div>
          <div class="w-6 ml-4" />
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="prev"

        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    search: {
      type: String,
      default: null
    },
    filtrage: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        prev,
        next
      },
      client: [],
      charge: true,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      devisPaginate: [],
      temoin: null,
      dataClient: [],
      temoinSearch: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      const retour = this.client

      if (this.filtrage !== null && this.temoin !== this.filtrage) {
        this.getSearch(this.filtrage)
      }

      if (this.search !== null && this.search !== '') {
        if (this.search.length > 2 && this.search !== this.temoinSearch){

          const obj = {
            name: this.search
          }

          this.getSearch(obj)
        }
      }

      return retour
    }
  },

  created () {
    if (this.$route.query.x){
      this.findUser()
    } else {
      this.fullClient()
      this.allClient()
    }
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allClient()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allClient()
      }
    },

    activeCharge(value){
      this.charge = value
    },

    getSearch (obj) {
      this.charge = true
      this.temoin = this.filtrage
      this.temoinSearch = this.search
      http.post(apiroutes.baseURL + apiroutes.filtreClient, obj)
          .then(response => {
            this.client = response
            this.charge = false
            this.$emit('user', response.length)
            this.$emit('filt', null)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

   fullClient () {
      http.post(apiroutes.baseURL + apiroutes.filterClient)
          .then(response => {
            this.dataClient = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },


    allClient () {
      http.get(apiroutes.baseURL + apiroutes.getClient + '?page=' + this.page + '&limit=' + this.limite)
          .then(response => {
            this.client = response.data
            this.charge = false
            this.total = response.total
            // console.log(response.data)
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
            this.$emit('user', response.total)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.data.statusCode === 401){
              this.$router.go('/')
            }
          })
    },

    findUser () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.getClient + '?id=' + this.$route.query.x)
          .then(response => {
            // console.log('user finder')
            // console.log(response)
            this.$emit('info', response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    selected (item) {
      this.$emit('info', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
