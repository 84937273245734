<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Recherche avancée
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-left text-c13 mt-6">
              Nom du client
            </div>
            <inputo
                placeholder="Ex: Cédric"
                @info="retourNom"
            />

            <div class="text-left text-c13 mt-6">
              Type de client
            </div>
            <multiselect
                :option="allType"
                background="white"
                class="mt-2"
                width="40%"
                @info="retourType"
            />

            <div class="mt-6">
              <div class="text-left text-c13 mt-6">
                Date de naissance
              </div>
              <div class="flex mt-2 items-center">
                <div class="w-1/2">
                  <datepicker
                      placeholder="Date de naissance"
                      :max-date="new Date()"
                      :choseTime="false"
                      format="dd-MM-yyyy"
                      @info="retourStart"
                  />
                </div>
                <div class="text-c18 ml-6">-</div>
                <div class="w-1/2 ml-6">
                  <datepicker
                      placeholder="Date de naissance"
                      :max-date="new Date()"
                      :choseTime="false"
                      format="dd-MM-yyyy"
                      @info="retourEnd"
                  />
                </div>
              </div>
            </div>

            <div class="mt-6 flex">
                <div class="w-1/2">
                  <div class="text-left text-c13 mb-1">
                    Profession
                  </div>
                  <inputo
                      placeholder="Ex: Informaticien"
                      @info="retourProfession"
                  />
                </div>

                <div class="w-1/2 ml-6">
                  <div class="text-left text-c13 mb-1">
                    Ville
                  </div>
                  <inputo
                      placeholder="Ex: Cotonou"
                      @info="retourVille"
                  />
                </div>
            </div>

            <div class="mt-6">
              <div class="text-left text-c13 mb-1">
                Ancienneté (Mois)
              </div>
              <inputo
                  placeholder="Ex: 3"
                  @info="retourMois"
              />
            </div>

            <div v-if="allAgence.length > 0">
              <div class="text-left text-c13 mt-6 mb-1">
                Agence d’enregistrement
              </div>
              <multiselect
                  :option="allAgence"
                  background="white"
                  value-t="Cliquez pour Selectionner"
                  class="mt-2"
                  width="40%"
                  @info="retourAgence"
              />
            </div>

            <div class="flex justify-center mt-10">
              <buton
                  class="w-2/3"
                  label="Rechercher"
                  height="55px"
                  :charge="charge"
                  @oga="continuer"
              />
            </div>
          </div>

        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import ic_agence from '../../assets/icons/agency.svg'
import fermer from '../../assets/icons/fermer.svg'
import search from '../../assets/icons/search.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'
import multiselect from '../helper/form/multiselect'
import datepicker from '../helper/form/datePicker'
import buton from '../helper/add/button'

export default {
  name: 'Success',
  components: {
    PopupBase,
    inputo,
    multiselect,
    datepicker,
    buton
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        search
      },
      error: null,
      charge: true,
      allType: ['Client direct', 'Client partenaire', 'Client prospecté'],
      valueType: '',
      endDate: '',
      startDate: '',
      profession: '',
      ville: '',
      anciennete: 0,
      allAgence: [],
      agence: [],
      valueAgence: '',
      valueNom: ''
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.getAgence()
  },

  methods: {
    continuer () {
      const body = {
        name: this.valueNom,
        type: "",
        profession: this.profession,
        city: this.ville,
        agency: "",
        startBirthday: this.startDate,
        endBirthday: this.endDate,
        seniority: this.anciennete
      }

      if (this.valueType === 'Client partenaire') {
        body.type = 'PARTNER'
      }

      if (this.valueType === 'Client direct') {
        body.type = 'DIRECT'
      }

      if (this.valueType === 'Client prospecté') {
        body.type = 'PROSPECTED'
      }

      for (let item in this.agence) {
        if (this.agence[item].label === this.valueAgence) {
          body.agency = this.agence[item].id
        }
      }

      this.$emit('oga', body)
    },

    retourStart (answer) {
      this.startDate = answer
    },

    retourEnd (answer) {
      this.endDate = answer
    },

    retourNom (answer) {
      this.valueNom = answer
    },

    retourAgence (answer) {
      this.valueAgence = answer
    },

    retourType (answer) {
      this.valueType = answer
    },

    retourMois (answer) {
      if (answer !== '') {
        this.anciennete = parseInt(answer)
      }
    },

    retourProfession (answer) {
      this.profession = answer
    },

    retourVille (answer) {
      this.ville = answer
    },


    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    getAgence () {
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            this.agence = response
            let tab = []

            for (let item in response) {
              tab.push(response[item].label)
            }
            this.allAgence = tab

            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }

    .cardinal {
      width: 50%;
      border-radius: 12px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
