<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/4">
            Nº DE RÉF.
          </div>
          <div class="w-1/4 ml-4">
            DATE
          </div>
          <div class="w-1/4 ml-4">
            STATUT
          </div>
          <div class="w-1/4 ml-4">
            MONTANT
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div class="text-center text-90 mt-16" v-if="allDevis.length === 0"> Aucun devis enregistré</div>

        <div
          v-for="(item, index) in allDevis"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../../assets/icons/arrow.svg'
import agenda from '../../../../assets/icons/agenda.svg'
import down from '../../../../assets/icons/down.svg'
import liste from './devisListe'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    donnes: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      charge: false,
      allDevis: []
    }
  },

  created () {
    const data = this.donnes
    this.allDevis = data
  },

  methods: {
    selected (item) {
      const url = window.location.origin + '/detailsDevis?x=' + item.id
      window.open(url, "_blank");
      // this.$router.push({ path: '/detailsDevis', query: { x: item.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
