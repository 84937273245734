<template>
  <div>
    <newEvent
      v-if="activeEvent"
      :donne="donnes"
      :activation="activeEvent"
      @oga="retourEvent"
    />

    <recharge
        v-if="activePaye"
        :donne="selectedItem"
        :activation="activePaye"
        @oga="retourPaye"
    />

    <div class="flex text-left">
      <div class="w-2/6 right-border pt-6">
        <img
          src="../../../../assets/images/jpg/login.jpg"
          alt=""
          class="h-82 w-82 rounded-6"
          v-if="tracker.meta.urlProfile === undefined || tracker.meta.urlProfile === null"
        >
        <img
            :src="tracker.meta.urlProfile"
            alt=""
            class="h-82 w-82 rounded-6"
            v-if="tracker.meta.urlProfile !== undefined && tracker.meta.urlProfile !== null"
        >
        <div class="mt-4 text-60 text-c14 font-c5">
          IDENTIFICATION
        </div>

        <div class="mt-10 text-90 text-c14">
          RÉFÉRENCE
        </div>
        <div class="text-c16">
          {{ tracker.id.toUpperCase().substr(0, 8) }}
        </div>

        <div class="mt-6 text-90 text-c14">
          Nom complet
        </div>
        <div class="text-c16">
          {{ tracker.surname }} {{ tracker.name }}
        </div>

        <div class="mt-6 text-90 text-c14">
          Téléphone
        </div>
        <div class="text-c16">
          {{ tracker.phone }}
        </div>

        <div class="text-c16" v-if="tracker.whatsapp !== null && tracker.whatsapp !== ''">
          {{ tracker.whatsapp }} (whatsapp)
        </div>

        <div class="text-c16" v-if="tracker.meta.ortherPhone">
          <div v-for="(item, index) in tracker.meta.ortherPhone" :key="index">
            {{ item }}
          </div>
        </div>

        <div class="mt-6 text-90 text-c14">
          Adresse
        </div>
        <div class="text-c16">
          {{ tracker.city }}
        </div>
        <div class="text-c16">
          {{ tracker.address }}
        </div>

        <div class="mt-6 text-90 text-c14">
          Date de naissance
        </div>
        <div class="text-c16">
          <span v-if="tracker.birthday">{{ conversion(tracker.birthday) }}</span>
          <span v-if="!tracker.birthday">-</span>
        </div>

        <div class="mt-6 text-90 text-c14">
          Profession
        </div>

        <div class="text-c16">
          {{ tracker.profession }} - {{ tracker.enterprise }}
        </div>

        <div class="mt-6 text-90 text-c14">
          Type de client
        </div>

        <div class="text-c16">
          <span v-if="tracker.type === 'DIRECT'">Client direct</span>
          <span v-if="tracker.type === 'PARTNER'">Client partenaire -
            <span v-if="tracker.insurance">
              <span v-if="tracker.insurance.length > 0">
                  {{ tracker.insurance.length > 0 ? tracker.insurance[0].name : '--'}}
              </span>

               <span v-if="tracker.meta.assurance && tracker.insurance.length > 0">
                 <span>
                  {{ !tracker.insurance[0].name ? tracker.meta.assurance.name : ''}}
                 </span>
                </span>
            </span>

          </span>
          <span v-if="tracker.type === 'PROSPECTED'">Client prospecté</span>
        </div>

        <div class="mt-6 text-90 text-c14">
          Date de création
        </div>
        <div class="text-c16">
          {{ conversion(tracker.created_at) }}
        </div>
      </div>

      <div class="w-3/4 pl-6">
        <div class="bloc flex p-8 items-center">
          <div class="w-1/2 pl-10 ">
            <div class="flex items-center">
              <div class="mr-6 text-c16 text-60 font-c6">
                Accompte du client
              </div>
              <div
                  class="addButton flex justify-center items-center text-white cursor-pointer"
                  @click="activePaye = true"
              >
                <div>+</div>
              </div>
            </div>

            <div class="text-yell text-c24 font-c5 mt-6">
              <span v-if="tracker.wallet">{{ tracker.wallet.amount.toLocaleString() }} FCFA</span>
            </div>
          </div>

          <div class="diviser" />

          <div class="w-1/2 pl-14 ">
            <div class="flex items-center">
              <div class="mr-6 text-c16 text-60 font-c6">
                Montant dû
              </div>
            </div>

            <div class="text-red-alert text-c24 font-c5 mt-6">
              <span v-if="stats !== null"> {{ stats.letToPayAmount.toLocaleString() }} </span> FCFA
            </div>
          </div>
        </div>

        <div class="mt-10">
          <div class="flex items-center">
            <div class="text-black text-c24 font-c5 w-4/5">
              Evènements
            </div>

            <div class="w-1/5 flex justify-end">
              <div
                class="addButton2 flex justify-center items-center font-c5 text-c18 text-white cursor-pointer"
                @click="activeEvent = true"
              >
                <div>+</div>
              </div>
            </div>
          </div>

          <div
              v-if="charge"
              class="flex justify-center mt-10"
          >
            <easy-spinner
                type="dots"
                size="70"
            />
          </div>

          <div v-if="eventListe.length === 0" class="text-90 text-c16 mt-16 text-center"> Aucun événement enregistré</div>

          <div
            v-for="(item, index) in eventListe"
            :key="index"
            class="ensemble p-6 mt-6"
          >
            <div class="flex items-center">
              <icon
                v-if="item.type === 'CALL'"
                :data="icons.call"
                height="25"
                width="25"
                class="mr-2 cursor-pointer"
                original
              />
              <icon
                v-if="item.type === 'COMPLIANT'"
                :data="icons.plainte"
                height="25"
                width="25"
                class="mr-2 cursor-pointer"
                original
              />

              <icon
                  v-if="item.type === 'REPAIR'"
                  :data="icons.reparation"
                  height="25"
                  width="25"
                  class="mr-2 cursor-pointer"
                  original
              />

              <icon
                  v-if="item.type === 'MAINTENANCE'"
                  :data="icons.entretien"
                  height="25"
                  width="25"
                  class="mr-2 cursor-pointer"
                  original
              />
              <icon
                v-if="item.type === 'VISIT'"
                :data="icons.visite"
                height="25"
                width="25"
                class="mr-2 cursor-pointer"
                original
              />
              <icon
                v-if="item.type === 'RDV'"
                :data="icons.note"
                height="25"
                width="25"
                class="mr-2 cursor-pointer"
                original
              />
              <icon
                  v-if="item.type === 'NOTE'"
                  :data="icons.note"
                  height="25"
                  width="25"
                  class="mr-2 cursor-pointer"
                  original
              />
              <div class="text-c22 font-c5">
                <span v-if="item.type === 'NOTE'"> NOTE </span>
                <span v-if="item.type === 'RDV'"> RDV </span>
                <span v-if="item.type === 'VISIT'"> VISITE </span>
                <span v-if="item.type === 'CALL'"> COMMUNICATION CLIENTELE </span>
                <span v-if="item.type === 'COMPLIANT'">PLAINTE </span>
                <span v-if="item.type === 'REPAIR'">REPARATION </span>
                <span v-if="item.type === 'MAINTENANCE'">ENTRETIEN </span>
              </div>
            </div>

            <div class="flex mt-4 items-center">
              <div
                class="diviserG"
                :class="{
                  'red': item.type === 'Plainte',
                  'yellow': item.type === 'Visite',
                  'blue': item.type === 'Note',
                }"
              />
              <div class="w-4/5 ml-4 text-c16 text-30">
                {{ item.note }}
              </div>
            </div>

            <div class="mt-2 text-c15">
              Date de l'événement: {{ conversion(item.date) }}
            </div>

            <div class="mt-4 text-c15">
              Par: {{ item.createdBy.fullname }}
            </div>

            <div class="mt-2 text-c15">
              Date d'édition: {{ conversion(item.created_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import call from '../../../../assets/icons/call.svg'
import reparation from '../../../../assets/icons/reparation.svg'
import entretien from '../../../../assets/icons/entretien.svg'
import plainte from '../../../../assets/icons/error.svg'
import visite from '../../../../assets/icons/visite.svg'
import note from '../../../../assets/icons/note.svg'
import newEvent from '../../../popup/newEvent'
import http from "../../../../plugins/https"
import apiroutes from "../../../../router/api-routes"
import recharge from '../../../popup/recharge'

export default {
  name: "Index",

  components: {
    newEvent,
    recharge
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    stats: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      icons: {
        call,
        plainte,
        visite,
        note,
        entretien,
        reparation
      },
      allNotif: [
        {
          type: 'Appel'
        },
        {
          type: 'Plainte'
        },
        {
          type: 'Visite'
        },
        {
          type: 'Note'
        },
      ],
      activeEvent: false,
      eventListe: [],
      charge: true,
      activePaye: false,
      selectedItem: null,
      user: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      return this.user
    }
  },

  created () {
    this.user = this.donnes
    this.selectedItem = {
      client: {
        id: this.donnes.id
      }
    }
    console.log(this.donnes)
    this.getAllEvent()
  },

  methods: {
    goBack () {
      this.$emit('oga', true)
    },

    retourPaye (answer) {
      if (answer) {
        this.getOneUser()
      }
      this.activePaye = false
    },

    retourEvent (answer) {
      if (answer) {
        this.getAllEvent()
      }
      this.activeEvent = false
    },

    conversion (answer) {
      return new Date(answer).toLocaleDateString()
    },

    getAllEvent () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.getEvent+ '?client=' + this.donnes.id)
          .then(response => {
            console.log('les evenements')
            console.log(response)
            this.eventListe = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    getOneUser () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.getClient+ '?id=' + this.donnes.id)
          .then(response => {
            this.user = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser {
  height: 73px;
  width: 1px;
  background-color: #DDDDDD;
}
.right-border{
  border-right:  0.5px solid #DDDDDD;
}
img {
  object-fit: cover;
}
.bloc {
  background: #F2F5F9;
  border-radius: 15.9626px;
}
.addButton{
  background: #099E9C;
  border-radius: 16.6047px;
  width: 51px;
  height: 37.95px;
}

.addButton2{
  background: #099E9C;
  border-radius: 22.3476px;
  width: 68.64px;
  height: 51.08px;
}
.diviserG{
  width: 3.19251px;
  height: 81.41px;
  background-color: #099E9C;
}
.ensemble {
  border: 0.798128px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 15.9626px;
}
.red{
  background-color: #F82626;
}
.yellow{
  background-color: #FBA705;
}
.blue {
  background-color: #5138EE;
}
</style>
